<template>
    <div>
        <b-card no-body>
            <b-overlay spinner-variant="primary" variant="white" opacity="1" :show="showLoader">
                <b-card-header>
                    <b-card-title>Image</b-card-title>
                    <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="primary"
                        class="btn-icon ml-auto"
                        @click="showModal = true;media_upload_type='image'"
                    >
                        Add Image
                        <feather-icon icon="PlusIcon" />
                    </b-button>
                </b-card-header>
                <div class="mb-2 mx-2">
                    <b-row>
                        <b-col
                            md="3"
                            cols="3"
                            class="profile-latest-img"
                            v-for="image in images"
                            :key="image.id"
                        >
                            <b-link @mouseenter="media=image.id" @mouseleave="media=0">
                                <b-overlay opacity="1" variant="transperant" :show="media == image.id">
                                    <b-img
                                        fluid
                                        rounded
                                        :src="image.media"
                                        alt="ddd"
                                    />
                                    <template #overlay>
                                        <div class="text-center">
                                            <b-button
                                                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                                variant="danger"
                                                class="btn-icon mr-75"
                                                size="sm"
                                                @click="deleteMedia(image)"
                                            >
                                                <feather-icon icon="Trash2Icon" />
                                            </b-button>
                                            <b-button
                                                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                                variant="success"
                                                class="btn-icon"
                                                size="sm"
                                                @click="previewMedia(image)"
                                            >
                                                <feather-icon icon="EyeIcon" />
                                            </b-button>
                                        </div>
                                    </template>
                                </b-overlay>
                            </b-link>
                        </b-col>
                        <b-col cols="12">
                            <!-- pagination -->
                            <div class="mt-3">
                                <b-pagination
                                    v-model="currentPage"
                                    align="center"
                                    :total-rows="images_rows"
                                    :per-page="perPage"
                                    @change="handlePaginationChange"
                                    first-number
                                    last-number
                                    prev-class="prev-item"
                                    next-class="next-item"
                                >
                                    <template #prev-text>
                                        <feather-icon
                                            icon="ChevronLeftIcon"
                                            size="18"
                                        />
                                    </template>
                                    <template #next-text>
                                        <feather-icon
                                            icon="ChevronRightIcon"
                                            size="18"
                                        />
                                    </template>
                                </b-pagination>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </b-overlay>
        </b-card>
        <b-card no-body>
            <b-overlay spinner-variant="primary" variant="white" opacity="1" :show="showLoaderVideo">
                <b-card-header>
                    <b-card-title>Video</b-card-title>
                    <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="primary"
                        class="btn-icon ml-auto"
                        @click="showModal = true;media_upload_type='video'"
                    >
                        Add Video
                        <feather-icon icon="PlusIcon" />
                    </b-button>
                </b-card-header>
                <div class="mb-2 mx-2">
                    <b-row>
                        <b-col
                            md="3"
                            cols="3"
                            class="profile-latest-img"
                            v-for="video in videos"
                            :key="video.id"
                        >
                            <b-link @mouseenter="media=video.id" @mouseleave="media=0">
                                <b-overlay opacity="1" variant="transperant" :show="media == video.id">
                                    <b-embed type="video" aspect="4by3">
                                        <source :src="video.media">
                                    </b-embed>
                                    <template #overlay>
                                        <div class="text-center">
                                            <b-button
                                                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                                variant="danger"
                                                class="btn-icon mr-75"
                                                size="sm"
                                                @click="deleteMedia(video)"
                                            >
                                                <feather-icon icon="Trash2Icon" />
                                            </b-button>
                                            <b-button
                                                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                                variant="success"
                                                class="btn-icon"
                                                size="sm"
                                                @click="previewMedia(video)"
                                            >
                                                <feather-icon icon="EyeIcon" />
                                            </b-button>
                                        </div>
                                    </template>
                                </b-overlay>
                            </b-link>
                        </b-col>

                        <b-col cols="12">
                            <!-- pagination -->
                            <div class="mt-3">
                                <b-pagination
                                    v-model="currentPageVideo"
                                    align="center"
                                    :total-rows="videos_rows"
                                    :per-page="perPageVideo"
                                    @change="handlePaginationChangeVideo"
                                    first-number
                                    last-number
                                    prev-class="prev-item"
                                    next-class="next-item"
                                >
                                    <template #prev-text>
                                        <feather-icon
                                            icon="ChevronLeftIcon"
                                            size="18"
                                        />
                                    </template>
                                    <template #next-text>
                                        <feather-icon
                                            icon="ChevronRightIcon"
                                            size="18"
                                        />
                                    </template>
                                </b-pagination>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </b-overlay>
        </b-card>

        <!-- basic modal -->
        <b-modal
            id="modal-1"
            title="Media Library"
            v-model="showModal"
            ok-only
            ok-title="Done"
            @ok="resetFiles"
            @show="resetFiles"
            @hidden="resetFiles"
        >
            <b-row>
                <b-col cols="12">
                    <b-form-file
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        multiple
                        v-model="files"
                        ref="files"
                        :accept="media_upload_type == 'video' ? 'video/*':'image/*'"
                        @input="fileChange"
                    />
                </b-col>

                <b-col cols="12">
                    <div
                        class="d-flex justify-content-start align-items-center mt-3"
                        v-for="file in uploadFiles"
                        :key="file.id"
                    >
                    <b-avatar
                        class="mr-50"
                        size="40"
                        variant="light-primary"
                        rounded
                    >
                        <feather-icon size="19" :icon="file.type.split('/')[0] == 'video' ? 'VideoIcon':'ImageIcon'" />
                    </b-avatar>
                    <div class="user-page-info" style="width: 350px;">
                        <h6 class="mb-0">{{file.name}}</h6>
                        <b-progress
                            :max="max"
                            :class="file.status == 'progress' ? 'progress-bar-warning': file.status == 'done' ? 'progress-bar-success':'progress-bar-danger'"
                            style="margin-top: 5px;"
                        >
                            <b-progress-bar
                                :value="file.progress"
                                :label="`${((file.progress / max) * 100).toFixed(0)}%`"
                                :variant="file.status == 'progress' ? 'warning': file.status == 'done' ? 'success':'failed'"
                            />
                        </b-progress>
                    </div>
                        <b-spinner v-if="file.status == 'progress'" type="grow" variant="warning" small class="mr-1 ml-auto"/>
                        <b-button
                            v-if="file.status == 'done'"
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            variant="success"
                            class="btn-icon ml-auto"
                            size="sm"
                        >
                            <feather-icon icon="CheckIcon" />
                        </b-button>
                        <b-button
                            v-if="file.status == 'failed'"
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            variant="danger"
                            class="btn-icon ml-auto"
                            size="sm"
                        >
                            <feather-icon icon="AlertCircleIcon" />
                        </b-button>
                    </div>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import { BCard, BRow, BCol, BLink, BImg, BButton, BCardTitle, BCardHeader, BCardText, BEmbed, BPagination, BModal, BFormFile, BMedia,
    BProgress, BProgressBar, BAvatar, BSpinner, BOverlay
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive'
import axios from 'axios';
export default {
    components: {
        BCard, BRow, BCol, BLink, BImg, BButton, BCardTitle, BCardHeader, BCardText, BEmbed, BPagination, BModal, BFormFile, BMedia,
        BProgress, BProgressBar, BAvatar, BSpinner, BOverlay
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            media: 0,
            showLoader: true,
            showLoaderVideo: true,
            max:100,
            showModal: false,
            currentPage: 1,
            perPage: 12,
            images_rows: 0,
            currentPageVideo: 1,
            perPageVideo: 12,
            videos_rows: 0,
            files: null,
            media_upload_type: null,
            images: [],
            videos: [],
            uploadFiles: [],
        }
    },
    mounted(){
        this.getVideos()
        this.getImages()
    },
    methods: {
        deleteMedia(media){
            this.$swal({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Yes, delete it!',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    axios.delete(`/gallery/${media.id}`)
                    .then((response) =>{
                        this.getMedias()
                        this.$swal({
                            icon: 'success',
                            title: 'تم الحذف!',
                            text: 'تم حذف صفحة بنجاح.',
                            confirmButtonText: 'حسنا',
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        })
                    })
                    .catch((error) => {
                        console.log(JSON.stringify(error));
                    })
                }
            })
        },
        previewMedia(media){
            window.open(media.media, '_blank');
        },
        resetFiles(){
            this.uploadFiles = []
        },
        async fileChange(){
            console.log(this.files);
            await this.files.forEach(item => {
                const indice = this.uploadFiles.length
                this.uploadFiles.push({
                    id: indice,
                    name: item.name,
                    type: item.type,
                    status: 'progress',
                    progress: 1,
                })

                let formData = new FormData()
                formData.append('brand', this.$store.state.auth.user.current_brand)
                formData.append('media', item)
                formData.append('type', item.type.split('/')[0])

                axios.post('/gallery/', formData, 
                {
                    headers:{"Content-Type": "multipart/form-data"},
                    onUploadProgress: function( progressEvent ) {
                        this.uploadFiles[indice].progress = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
                        if (this.uploadFiles[indice].progress == 100){
                            this.uploadFiles[indice].status = 'done'
                        }
                    }.bind(this)
                })
                .then(response => {
                    if (response.data.type == "image"){
                        this.images.push(response.data)
                    } else {
                        this.videos.push(response.data)
                    }
                })
                .catch(error => {
                    this.uploadFiles[indice].status = 'failed'
                    this.uploadFiles[indice].progress = 100
                    console.log(error);
                })
            })
        },
        async handlePaginationChange(value){
            this.currentPage = value
            await this.getImages()
        },
        async handlePaginationChangeVideo(value){
            this.currentPageVideo = value
            await this.getVideos()
        },
        async getImages(){
            this.showLoader = true
            await axios.get(`/gallery?brand_id=${this.$store.state.auth.user.current_brand}&type=image&p=${this.currentPage}`)
            .then(response => {
                console.log(response);
                this.images_rows = response.data.count
                this.images = response.data.results
                this.showLoader = false
            })
            .catch(error => {
                console.log(error);
            })
        },
        async getVideos(){
            this.showLoaderVideo = true
            await axios.get(`/gallery?brand_id=${this.$store.state.auth.user.current_brand}&type=video&p=${this.currentPageVideo}`)
            .then(response => {
                console.log(response);
                this.videos_rows = response.data.count
                this.videos = response.data.results
                this.showLoaderVideo = false
            })
            .catch(error => {
                console.log(error);
            })
        }
    }
}
</script>

<style lang="scss">
  .profile-latest-img {
    transition: all 0.2s ease-in-out;
    &:hover {
      transform: translateY(-3px) scale(1.1);
      z-index: 5;
    }
    img {
      margin-top: 1.28rem;
    }
  }
</style>